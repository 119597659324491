<template>
  <b-container class="import-wizard-step import-wizard-step02">
    <b-row no-gutters>
      <b-col cols="4" class="detail-text text-left py-5 px-5" align-self="start">
        <h2 class="text-dark">{{ $t(translationPath + 'intro_title') }}</h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="8" class="detail-form text-left py-5 px-5" align-self="center">

        <h3>{{ $t(translationPath + 'required_columns') }}</h3>
        <p>{{ $t(translationPath + 'required_columns_description') }}</p>
        <!-- Date -->
        <b-form-group
          label-for="date"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidDateColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_date') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_date_help_text')}}</template>
          <b-form-select id="date"
            v-model="matched.date"
            :options="fileColumnOptions"
            :state="$v.matched.date.$dirty ? !$v.matched.date.$error : null"
            @input="$v.matched.date.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Title -->
        <b-form-group
          label-for="title"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidTitleColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_title') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_title_help_text')}}</template>
          <b-form-select id="title"
            v-model="matched.title"
            :options="fileColumnOptions"
            :state="$v.matched.title.$dirty ? !$v.matched.title.$error : null"
            @input="$v.matched.title.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Amount -->
        <b-form-group
          label-for="amount"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidAmountColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_amount') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_amount_help_text')}}</template>
          <b-form-select id="amount"
            v-model="matched.amount"
            :options="fileColumnOptions"
            :state="$v.matched.amount.$dirty ? !$v.matched.amount.$error : null"
            @input="$v.matched.amount.$touch()"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ImportAccountStatementSpreadsheetWizardStep02',
  props: ['columns', 'fileName', 'defaultOffsetAccount'],
  validations: {
    matched: {
      date: { required },
      title: { required },
      amount: { required }
    },
    form: ['matched.date', 'matched.title', 'matched.amount']
  },
  data () {
    return {
      translationPath: 'transactions.import.import-spreadsheet-wizard.step2.',
      matched: {
        date: null,
        title: null,
        amount: null,
        description: null,
        type: null,
        category: null,
        offsetAccount: null,
        percentage: null,
        form_account_data: {}
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    showAlertDanger () {
      const successRate = this.form_account_data && Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'success_rate') ? this.form_account_data.parse_result.success_rate : -1
      return successRate === 0
    },
    helpLink () {
      switch (this.$i18n.locale) {
        case 'sv':
          return '5e32e29104286364bc94a3b0'
        case 'da':
          return '5dd7959304286364bc921113'
        default:
          return '5dd792b604286364bc9210fd'
      }
    },
    fileColumnOptions () {
      const columns = [{ text: '', value: '' }]
      let columnNumber = 0
      for (var prop in this.columns) {
        columnNumber++
        const item = {
          text: this.columns[prop],
          value: columnNumber
        }
        columns.push(item)
      }
      return columns
    },
    invalidDateColumnFeedback () {
      if (this.$v.matched.date.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidTitleColumnFeedback () {
      if (this.$v.matched.title.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidAmountColumnFeedback () {
      if (this.$v.matched.amount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    }
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (isValid) {
        this.$bvToast.toast(this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_description'), {
          title: this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_title'),
          variant: 'info',
          solid: true
        })
        const responseData = await this.parseFile()
        const responseObject = Object.prototype.hasOwnProperty.call(responseData, 'data') && responseData.data ? responseData.data : {}
        const parsedTransaction = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_ok') ? responseObject.with_parse_ok : []
        const parsedError = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_error') ? responseObject.with_parse_error : []
        const parsedRawData = Object.keys(responseData).filter(item => item !== 'data').map(item => {
          const Obj = {}
          Obj[item] = responseData[item]
          return Obj
        }).reduce((acc, item) => Object.assign(acc, item))
        const mutatedTransactions = parsedTransaction.map(item => {
          const itemType = item.suggested_accounts && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'primary_suggestion_account_id') && item.suggested_accounts.primary_suggestion_account_id != null && item.suggested_accounts.primary_suggestion_account_id >= 0 ? item.suggested_accounts.suggestions[item.suggested_accounts.primary_suggestion_account_id].transaction_type : item.type
          const itemTypeIdentified = item.is_identified_as_transfer === true ? item.is_identified_as_transfer_type : itemType
          const returnObj = {
            title: item.title,
            date: item.date,
            amount: item.amount ? Number(item.amount) : 0,
            raw_string: `${item.date}\t${item.title}\t${itemTypeIdentified}\t${item.amount}`,
            balance: item.balance,
            transaction_type: item.transaction_type,
            transaction_category: item.transaction_category,
            duplicate: item.is_potential_duplicate || 0,
            duplicate_transfer: item.is_potential_duplicate_transfer || 0,
            suggestion_account_id: item.suggested_accounts && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'primary_suggestion_account_id') && item.suggested_accounts.primary_suggestion_account_id != null && item.suggested_accounts.primary_suggestion_account_id >= 0 ? item.suggested_accounts.primary_suggestion_account_id : -1, // See if we got a suggestion id, if yes, set it or else set -1
            type: itemTypeIdentified, // Check if suggestion comes with a suggestion of transcation_type, if yes, set it. Else use the one returned by parser
            accounts: null,
            meta: item.meta
          }

          if (returnObj.suggestion_account_id !== -1 && returnObj.suggestion_account_id === this.offsetAccountId) { // if suggestion id same as selected, then null it.
            Object.assign(returnObj, { suggestion_account_id: -1 })
          }
          const defaultActivePercentage = returnObj.suggestion_account_id > -1 && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'suggestions') && item.suggested_accounts.suggestions && Object.keys(item.suggested_accounts.suggestions).indexOf(returnObj.suggestion_account_id.toString()) > -1 && Object.prototype.hasOwnProperty.call(item.suggested_accounts.suggestions[returnObj.suggestion_account_id], 'default_active_percentage') && item.suggested_accounts.suggestions[returnObj.suggestion_account_id].default_active_percentage ? item.suggested_accounts.suggestions[returnObj.suggestion_account_id].default_active_percentage : 0
          return Object.assign(returnObj, { percentage: defaultActivePercentage, is_duplicate: returnObj.duplicate === 1, is_duplicate_transfer: returnObj.duplicate_transfer === 1 })
        })
        this.form_account_data = Object.assign({}, this.form_account_data, {
          parsed_account_statement: responseObject,
          parsed_transactions: parsedTransaction,
          transactions: mutatedTransactions,
          transactions_error: parsedError,
          parse_result: parsedRawData
        })

        const transactionRawData = Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'raw_data_string') && this.form_account_data.parse_result.raw_data_string ? this.form_account_data.parse_result.raw_data_string : ''
        // save transaction Object
        const importTitle = this.$t('transactions.import.import_wizard_v2.step4.import_title_default_suggestion_v2', {
          date: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          parser: this.form_account_data.parse_result.parser_nice_name === null ? this.form_account_data.parse_result.parser_class_name : this.$t('common.' + this.form_account_data.parse_result.parser_nice_name)
        })
        const createImportObject = {
          account_id: this.defaultOffsetAccount ? this.defaultOffsetAccount.id : null,
          title: importTitle,
          raw_account_statement: transactionRawData,
          used_parser: this.form_account_data.parse_result.parser_class_name,
          success_rate: this.form_account_data.parse_result.success_rate
        }
        const bankStatementResponse = await axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/create`, createImportObject)
        const bankResponse = bankStatementResponse.status === 201 ? bankStatementResponse.data.data : {}
        if (Object.keys(bankResponse).length === 0) {
          return false
        }
        const importId = Object.prototype.hasOwnProperty.call(bankResponse, 'id') ? bankResponse.id : null
        if (this.form_account_data.parse_result.entries_passed > 0) {
          // toast success message
          const toastSuccessMessage = this.$t('transactions.import.import_wizard_v2.step2.alert.successfully_identified_transactions')
          // toast error message
          const toastErroreMessage = this.$t('transactions.import.import_wizard_v2.step3.all_transcations_could_not_be_parsed_title', { parserName: this.$t('common.' + this.form_account_data.parse_result.parser_nice_name) })
          // toast heading
          const toastHeading = this.form_account_data.parse_result.entries_failed === 0 ? toastSuccessMessage : toastErroreMessage
          // toast message
          const toastMessage = this.$t('transactions.import.import_wizard_v2.step2.alert.successfully_identified_transactions_description', { number: this.form_account_data.parse_result.entries_passed })
          // toast variant
          const toastvariant = this.form_account_data.parse_result.entries_failed === 0 ? 'success' : 'warning'
          // toast
          this.$bvToast.toast(toastMessage, {
            title: toastHeading,
            variant: toastvariant,
            solid: true,
            id: 'parseTransactions',
            autoHideDelay: 5000
          })
        }
        this.$emit('validate-success', Object.assign({}, { import_id: importId }, this.form_account_data))
        this.$emit('reset-edit-state')
        if (this.showAlertDanger) {
          this.$bvToast.toast(this.$t('transactions.import.import_wizard_v2.step2.toast.parse_failed_description'), {
            title: this.$t('transactions.import.import_wizard_v2.step2.toast.parse_failed_title'),
            variant: 'danger',
            solid: true
          })
          this.scrollToError()

          return false
        }
        return true
      }
      return isValid
    },
    parseFile () {
      if (!this.matched.amount || !this.matched.date || !this.matched.title) {
        return false
      }
      const postData = {
        filename: this.fileName,
        matched_columns: this.matched,
        default_offset_account: this.defaultOffsetAccount.id
      }

      return axios.post(process.env.VUE_APP_ROOT_API + '/transactions/import-spreadsheet/parse', postData)
        .then(response => response.data.data)
        .then(response => {
          return response
          // return response.filter(item => item.date && item.title)
        })
        .catch((error) => {
          console.error(error)
          return null
        })
    }
  },
  watch: {
    columns: {
      deep: true,
      handler () {
        for (var index in this.columns) {
          const colnum = parseInt(index) + 1
          switch (this.columns[index].toLowerCase()) {
            case 'date':
              this.matched.date = colnum
              break
            case 'title':
              this.matched.title = colnum
              break
            case 'amount':
              this.matched.amount = colnum
              break
            case 'description':
            case 'descr':
              this.matched.description = colnum
              break
            case 'type':
            case 'transaction type':
            case 'transaction_type':
            case 'transactiontype':
            case 'account type':
            case 'account_type':
            case 'accounttype':
            case 'category type':
            case 'category_type':
            case 'categorytype':
              this.matched.type = colnum
              break
            case 'account':
            case 'category':
              this.matched.category = colnum
              break
            case 'offset account':
            case 'offset_account':
            case 'offsetaccount':
            case 'offset categoty':
            case 'offset_category':
            case 'offsetcategory':
              this.matched.offsetAccount = colnum
              break
            case 'percentage':
            case 'active percentqge':
            case 'active_percentage':
            case 'activepercentage':
              this.matched.percentage = colnum
              break
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.import-wizard-step02 {
  .detail-form {
    label {
      line-height: 32px;
    }
  }
  .dotted {
    padding-top: 25px;
    border-top: 1px dashed gray;
  }
}
</style>
